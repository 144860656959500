@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: #2563eb;
  text-decoration: none;
}


* {
  box-sizing: border-box;
}
p {
  margin-bottom: 2rem;
  overflow-wrap: break-word;
  hyphens: manual;
  color:#4b5563
}

strong {
  color:#111827;
}


ul {
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: rgb(75 85 99);
}
li {
  margin-bottom: 4px;
}

h1 {
  display: block;
  font-size: 2em;
  margin-top: 3em;
  color:#111827;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bolder;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 3em;
  color:#1f2937;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  color:#1f2937;
  margin-bottom: 0.5em;
  margin-left: 0;
  margin-right: 0;
  font-weight:500;
}

h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h5 {
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h6 {
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
